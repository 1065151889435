import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoadingState } from "./types";

const initialState: LoadingState = {
    isLoading: false
};

export const loading = createSlice({
    name: "loading",
    initialState: initialState,
    reducers: {
        setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
            return {
                isLoading: payload
            };
        }
    }
});

export const { setIsLoading } = loading.actions;

export default loading.reducer;
