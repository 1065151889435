import { ShopifyProductWithOptions } from "api/liveCommentsReceiver/types";

export interface ShoppingState {
    currentView: ShoppingViews;
    showShopping: boolean;
    selectedProduct: ShopifyInfoProduct | null | undefined;
    products: ShopifyProductWithOptions[];
    answers: VariantOption[];
    cart: FinalVariant[];
    shopId: string;
    loading: boolean;
}

export enum ShoppingViews {
    Products = "Products",
    ProductDetails = "ProductDetails",
    Cart = "Cart"
}

export interface ShopifyInfoProduct {
    title: string;
    code: string;
    productId: string;
    handle?: string;
    image?: string;
    minPrice: string;
    maxPrice: string;
    currencyCode: string;
}

export interface ShopifyProduct {
    id: string;
    title: string;
    handle: string;
    options?: TruthyProductOption[];
    variants?: ProductEdge;
    finalVariant?: ProductVariant;
}

export interface ProductOption {
    name: string;
    values: string[];
}

export interface TruthyProductOption {
    name: string;
    values: TruthyProductValue[];
}

export interface TruthyProductValue {
    value: string;
    isAvailable: boolean;
}

export interface ProductEdge {
    edges: ProductNode[];
}

export interface ProductNode {
    node: ProductVariant;
}

export interface ProductVariant {
    id: string;
    title: string;
    availableForSale: boolean;
    quantityAvailable: number;
    selectedOptions: ProductOption[];
    image: VariantImage;
    priceV2: VariantPrice;
}

export interface VariantImage {
    id: string;
    url: string;
}

export interface VariantOption {
    name: string;
    value: string;
}

export interface VariantPrice {
    amount: string;
    currencyCode: string;
}

export interface FinalVariant {
    finalVariant: ProductVariant | undefined;
    qty: number;
    title: string | undefined;
}

export interface CartItemQty {
    id: string | undefined;
    qty: number;
}
