export interface NotificationState {
    shoppingNotifications: InnerNotificationState;
    interactiveNotifications: InnerNotificationState;
    globalNotifications: InnerNotificationState;
}

export interface InnerNotificationState {
    notifications: Notification[];
    lastId: number;
}

export interface Notification {
    id: number;
    class: NotificationClass;
    message: string;
    type: NotificationType;
    messageOptions?: any;
    fadeMilliseconds?: number;
    clickText?: string;
    clickAction?: (...args: any) => any;
}

export type AddNotificationPayload = Omit<Notification, "id">;

export enum NotificationType {
    Success = "success",
    Info = "info",
    Danger = "danger"
}

export type NotificationClass = "shopping" | "interactives" | "global";
