import { createSlice, Reducer } from "@reduxjs/toolkit";
import { PlayerCreatorState } from "./types";
import { getCreator } from "./thunks";

const initialState = {
    details: {
        organizationId: undefined,
        projectId: undefined,
        stripeAccountId: undefined,
        userId: undefined
    }
} as PlayerCreatorState;

export const playerCreatorSlice = createSlice({
    name: "playerCreator",
    initialState: initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getCreator.fulfilled, (state, action) => {
            return {
                ...action.payload
            } as PlayerCreatorState;
        });
        builder.addCase(getCreator.rejected, (_, { error }) => {
            throw error;
        });
    }
});

export default playerCreatorSlice.reducer as Reducer<PlayerCreatorState>;
