import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { Modals } from "store/Modals/types";
import { useDispatch } from "react-redux";
import { setActiveModal } from "store/Modals/slice";
import { IFrameType } from "store/VideoSession/types";
import { useHandleRestore } from "hooks/useHandleRestore";
import { useGatedContent } from "hooks/useGatedContent";
import { GenericModal } from "../GenericModal";
import { EmailInput } from "components/inputs/text-input/EmailInput";
import { validEmail } from "helpers/email";

export const RestorePurchaseModal: React.FC = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<string | undefined>();
    const dispatch = useDispatch();
    const { isLoading } = useSelector((s: RootState) => s.loading);
    const { iframeType, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );

    const handleRestore = useHandleRestore();
    const restoreAccess = (email: string) => {
        handleRestore(email, {
            postRestore: () => {
                dispatch(setActiveModal(Modals.PurchaseRestored));
            },
            onError: () => {
                setError(t("errors:restore-purchase"));
            }
        });
    };

    const { customerEmail } = useGatedContent();
    const [email, setEmail] = useState<string | null>(null);

    useEffect(() => {
        if (!email && customerEmail) {
            setEmail(customerEmail);
        }
    }, [customerEmail, email]);

    return (
        <GenericModal
            title={t("modals:restore-title")}
            successButton={t("buttons:restore-access")}
            onSuccess={() => {
                restoreAccess(email ?? "");
            }}
            onClose={() => {
                iframeType === IFrameType.AuxModal &&
                    parentFrame?.closePurchaseModal();
            }}
            successButtonDisabled={
                !validEmail(email ?? customerEmail ?? "") || isLoading
            }
            preventDismiss={isLoading}
            preventCloseOnSuccess={true}
            isProcessing={isLoading}
            processingHeader={t("modals:processing")}
            processingDescriptionLines={[t("modals:processing-description")]}
        >
            <p>{t("modals:restore-description")}</p>
            <EmailInput
                value={email ?? customerEmail ?? ""}
                onChange={(event) => setEmail(event.target.value)}
                error={error}
                isLoading={isLoading}
            />
        </GenericModal>
    );
};
