import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    CartItemQty,
    FinalVariant,
    ShoppingState,
    ShoppingViews,
    VariantOption
} from "./types";
import { LCRApiResponse, ShopifyProductWithOptions } from "../../api/types";

export const shopping = createSlice({
    name: "shopping",
    initialState: {
        currentView: ShoppingViews.Products,
        showShopping: false,
        selectedProduct: null,
        products: [],
        answers: [],
        cart: [],
        shopId: "",
        loading: false
    } as ShoppingState,
    reducers: {
        toggleShopping: (state, { payload }: PayloadAction<boolean>) => {
            return {
                ...state,
                showShopping: payload
            };
        },
        updateSelectedProduct: (
            state,
            {
                payload
            }: PayloadAction<ShopifyProductWithOptions | null | undefined>
        ) => {
            return {
                ...state,
                currentView: ShoppingViews.ProductDetails,
                selectedProduct: payload,
                answers: []
            };
        },
        addNewProduct: (
            state,
            { payload }: PayloadAction<ShopifyProductWithOptions>
        ) => {
            if (state.products.find((p) => p.productId === payload.productId))
                return;
            state.products = [
                {
                    ...payload
                },
                ...state.products
            ];
        },
        updateProducts: (state, { payload }: PayloadAction<LCRApiResponse>) => {
            const payloadHasProducts = payload.products.length > 0;
            const selectedProductInPayload = !!payload.products.find(
                (item) => item?.code === state.selectedProduct?.code
            );

            return {
                ...state,
                currentView: ShoppingViews.Products,
                products: payload.products.sort((a, b) =>
                    a.code < b.code ? 1 : -1
                ),
                shopId: payload.shopId,
                cart: payloadHasProducts ? state.cart : [],
                selectedProduct:
                    payloadHasProducts && selectedProductInPayload
                        ? state.selectedProduct
                        : null
            };
        },
        updateAnswers: (state, { payload }: PayloadAction<VariantOption[]>) => {
            return {
                ...state,
                answers: payload
            };
        },
        addToCart: (state, { payload }: PayloadAction<FinalVariant>) => {
            const exists = state.cart.find(
                (item) => item?.finalVariant?.id === payload?.finalVariant?.id
            );
            let updatedCart: FinalVariant[] = [];
            if (exists) {
                updatedCart = state.cart.map((item) => {
                    if (item?.finalVariant?.id === payload?.finalVariant?.id) {
                        return {
                            ...item,
                            qty: item.qty + payload.qty
                        };
                    } else {
                        return {
                            ...item
                        };
                    }
                });
            } else {
                updatedCart = [
                    ...state.cart,
                    {
                        ...payload
                    }
                ];
            }

            return {
                ...state,
                cart: updatedCart
            };
        },
        updateCartItemQty: (state, { payload }: PayloadAction<CartItemQty>) => {
            return {
                ...state,
                cart: state.cart.map((item) => {
                    if (item?.finalVariant?.id === payload?.id) {
                        return {
                            ...item,
                            qty: payload.qty
                        };
                    }

                    return {
                        ...item
                    };
                })
            };
        },
        changeScreen: (state, { payload }: PayloadAction<ShoppingViews>) => {
            return {
                ...state,
                currentView: payload,
                selectedProduct:
                    payload === ShoppingViews.Cart
                        ? null
                        : state.selectedProduct
            };
        },
        removeCartItem: (state, { payload }: PayloadAction<FinalVariant>) => {
            return {
                ...state,
                cart: state.cart.filter(
                    (item) => item.finalVariant?.id !== payload.finalVariant?.id
                )
            };
        },
        clearCart: (state) => {
            state.cart = [];
        },
        setShoppingLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        }
    }
});

export const {
    toggleShopping,
    updateSelectedProduct,
    updateProducts,
    updateAnswers,
    addToCart,
    changeScreen,
    updateCartItemQty,
    removeCartItem,
    addNewProduct,
    setShoppingLoading,
    clearCart
} = shopping.actions;

export default shopping.reducer;
