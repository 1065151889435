import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "store/reducers";

const useCollectionWithVideos = () => {
    const selectCollections = (state: RootState) =>
        state.catalogState.collections.collections;
    const selectCollectionVideosMap = (state: RootState) =>
        state.catalogState.collectionVideosMap;
    const selectHasLoaded = (state: RootState) =>
        state.catalogState.hasLoadedCollectionVideos;

    const selectCollectionAndVideos = createSelector(
        [selectCollections, selectCollectionVideosMap, selectHasLoaded],
        (collections, collectionVideosMap, hasLoaded) => {
            const collection = collections?.[0];
            const collectionVideos =
                collectionVideosMap?.[collection?.details?.id ?? ""];

            //console.log("selector", { collection, collectionVideosMap, collectionVideos, hasLoaded });
            return { collection, collectionVideos, hasLoaded };
        }
    );

    return useSelector(selectCollectionAndVideos);
};

export default useCollectionWithVideos;
