import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Confirmation, ConfirmationState } from "./types";

export const confirmation = createSlice({
    name: "confirmation",
    initialState: {
        confirmationOpen: false,
        message: ``,
        titleText: "",
        cancelText: "Cancel",
        confirmText: "OK",
        onSuccess: Function,
        htmlMessage: false,
        onCancel: Function
    } as ConfirmationState,
    reducers: {
        openConfirmation: (state, { payload }: PayloadAction<Confirmation>) => {
            state.message = payload.message;
            state.titleText = payload.titleText ?? state.titleText;
            state.cancelText = payload.cancelText ?? state.cancelText;
            state.confirmText = payload.confirmText ?? state.confirmText;
            state.htmlMessage = payload.htmlMessage ?? state.htmlMessage;
            state.onSuccess = payload.onSuccess;
            state.confirmationOpen = true;
            state.onCancel = payload.onCancel ?? state.onCancel;
        },
        closeConfirmation: (state) => {
            state.confirmationOpen = false;
            state.htmlMessage = false;
            state.titleText = "";
            state.message = "";
            state.cancelText = "Cancel";
            state.confirmText = "OK";
            state.onCancel = () => {};
        }
    }
});
export const { openConfirmation, closeConfirmation } = confirmation.actions;

export default confirmation.reducer;
