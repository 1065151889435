import React from "react";
import styles from "./LoadingAnimation.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

const LoadingAnimation: React.FC = () => (
    <div className={cx("loader")}>
        <svg className={cx("circular")} viewBox="25 25 50 50">
            <circle
                className={cx("path")}
                cx="50"
                cy="50"
                r="20"
                fill="none"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </svg>
    </div>
);

export default LoadingAnimation;
