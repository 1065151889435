import React from "react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { Provider as RollbarProvider } from "@rollbar/react";
import getRollbarConfig from "helpers/getRollbarConfig";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "./index.scss";
import "./localization/i18n";

import { initSegment } from "helpers/analyticsHelpers";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
initSegment();

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const persistor = persistStore(store);
const rollbarConfig = getRollbarConfig();

root.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <BrowserRouter>
                <PersistGate loading={null} persistor={persistor}>
                    <RollbarProvider config={rollbarConfig}>
                        <App />
                    </RollbarProvider>
                </PersistGate>
            </BrowserRouter>
        </ReduxProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
