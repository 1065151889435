import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { setModalData } from "store/Modals/slice";
import { setIsLoading } from "store/Loading/slice";
import { useRedirectUrl } from "hooks/useRedirectUrl";
import { AppDispatch } from "store/store";
import { payments } from "api/payment/client";

export const useHandleRestore = () => {
    const dispatch = useDispatch<AppDispatch>();
    const playerCreator = useSelector((s: RootState) => s.playerCreator);
    const redirectUrl = useRedirectUrl();

    return useCallback(
        async (
            email: string,
            hooks: {
                preRestore?: () => void;
                postRestore?: () => void;
                onError?: () => void;
            } = {}
        ) => {
            dispatch(setIsLoading(true));
            const { preRestore, postRestore, onError } = hooks;
            try {
                if (preRestore) preRestore();
                // Checks to see if a user has purchased anything from the stripeAccount of the creator
                await payments.RestorePurchase({
                    EmailAddress: email,
                    StripeAccountId: playerCreator?.details?.stripeAccountId,
                    RedirectUrl: redirectUrl.href,
                    IsEmbed: true // we are always in an embed context now.
                });
                dispatch(setModalData({ email }));
                if (postRestore) postRestore();
            } catch (error) {
                if (onError) onError();
            } finally {
                dispatch(setIsLoading(false));
            }
        },
        [dispatch, playerCreator, redirectUrl]
    );
};
