import { createUrl } from "helpers/url";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store/reducers";

/** Gets URL that can be used to redirect user to current page after checkout / restore purchase process */
export const useRedirectUrl = () => {
    const { referrerUrl, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );

    return useMemo<URL>(() => {
        if (!!parentFrame) {
            return createUrl(
                referrerUrl ?? document.referrer ?? window.location.href,
                {
                    searchParams: {
                        "switcher-token": undefined
                    }
                }
            );
        } else {
            return createUrl(window.location.href, {
                searchParams: { "switcher-token": undefined }
            });
        }
    }, [parentFrame, referrerUrl]);
};
