import React from "react";
import { useDispatch } from "react-redux";
import { Notification } from "store/notification/types";
import { NotificationPopover } from "./NotificationPopover";
import { removeNotification } from "store/notification/slice";
import styles from "./NotificationContainer.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

interface NotificationContainerProps {
    /** A list of notifications. */
    notifications: Notification[];
    /** A value which determines if notifications should be shown. */
    visible: boolean;
    /** A value which determines the notification container's position attribute. */
    fixed?: boolean;
    /** A value which determines if notifications should fade or remain */
    shouldFade?: boolean;
}

/** A container element for notification popovers. */
export const NotificationContainer: React.FC<NotificationContainerProps> = ({
    notifications,
    visible,
    fixed = true,
    shouldFade
}) => {
    const dispatch = useDispatch();

    const close = (notification: Notification) => {
        dispatch(removeNotification({ ...notification }));
    };

    return (
        <div
            className={cx("notification-container", {
                "fixed": fixed,
                "show": visible
            })}
        >
            {notifications.map((n) => {
                return (
                    <div key={n.id} className="col-12">
                        <NotificationPopover
                            notification={n}
                            close={() => close(n)}
                            shouldFade={shouldFade ?? true}
                        />
                    </div>
                );
            })}
        </div>
    );
};
