import { useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useMemo } from "react";
import { CollectionVideoResponse } from "@switcherstudio/player-api-client";
import useCollectionWithVideos from "./useCollectionWithVideos";

export const useGatedContent = (
    specificBroadcast?: CollectionVideoResponse
) => {
    const { collection } = useCollectionWithVideos();

    const { currentCollectionVideo, isEmbed, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );
    const { catalog } = useSelector((s: RootState) => s.catalogState);

    const broadcastToCheck = useMemo(
        () => specificBroadcast ?? currentCollectionVideo,
        [specificBroadcast, currentCollectionVideo]
    );

    const catalogIsGated = useMemo(() => catalog?.isGated ?? false, [catalog]);
    const catalogIsEntitled = useMemo(
        () => catalog?.isEntitled ?? false,
        [catalog]
    );
    const catalogRequiresPurchase = useMemo(
        () => catalogIsGated && !catalogIsEntitled,
        [catalogIsEntitled, catalogIsGated]
    );

    const catalogPurchaseEntitlements = useMemo(() => {
        return (
            (catalog?.entitlements ?? collection?.entitlements)?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Catalog" &&
                    entitlement?.details?.redemptionType === "Purchase"
            ) ?? []
        );
    }, [catalog?.entitlements, collection?.entitlements]);

    const catalogEmailEntitlements = useMemo(() => {
        if (catalogPurchaseEntitlements?.length > 0) return [];
        return (
            (catalog?.entitlements ?? collection?.entitlements)?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Catalog" &&
                    entitlement?.details?.redemptionType === "Email"
            ) ?? []
        );
    }, [
        catalog?.entitlements,
        catalogPurchaseEntitlements?.length,
        collection?.entitlements
    ]);

    const catalogPasswordEntitlements = useMemo(() => {
        if (catalogPurchaseEntitlements?.length > 0) return [];
        return (
            (catalog?.entitlements ?? collection?.entitlements)?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Catalog" &&
                    entitlement?.details?.redemptionType === "Password"
            ) ?? []
        );
    }, [
        catalog?.entitlements,
        catalogPurchaseEntitlements?.length,
        collection?.entitlements
    ]);

    const catalogInvoices = useMemo(() => {
        return (
            catalogPurchaseEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [catalogPurchaseEntitlements]);

    const catalogEmailRedemptions = useMemo(() => {
        return (
            catalogEmailEntitlements
                ?.flatMap((entitlement) => entitlement.emailRedemptions)
                ?.filter((redemption) => !!redemption) ?? []
        );
    }, [catalogEmailEntitlements]);

    const catalogPasswordRedemptions = useMemo(() => {
        return (
            catalogPasswordEntitlements
                ?.flatMap((entitlement) => entitlement.passwordRedemptions)
                ?.filter((redemption) => !!redemption) ?? []
        );
    }, [catalogPasswordEntitlements]);

    const playerIsGated = useMemo(
        () => collection?.isGated ?? false,
        [collection]
    );
    const playerIsEntitled = useMemo(
        () => collection?.isEntitled ?? false,
        [collection]
    );
    const playerRequiresPurchase = useMemo(
        () => playerIsGated && !playerIsEntitled,
        [playerIsEntitled, playerIsGated]
    );
    const playerPurchaseEntitlements = useMemo(() => {
        return (
            collection?.entitlements?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Collection" &&
                    entitlement?.details?.redemptionType === "Purchase"
            ) ?? []
        );
    }, [collection?.entitlements]);

    const playerEmailEntitlements = useMemo(() => {
        if (playerPurchaseEntitlements?.length > 0) return [];
        return (
            collection?.entitlements?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Collection" &&
                    entitlement?.details?.redemptionType === "Email"
            ) ?? []
        );
    }, [collection?.entitlements, playerPurchaseEntitlements?.length]);

    const playerPasswordEntitlements = useMemo(() => {
        if (playerPurchaseEntitlements?.length > 0) return [];
        return (
            collection?.entitlements?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Collection" &&
                    entitlement?.details?.redemptionType === "Password"
            ) ?? []
        );
    }, [collection?.entitlements, playerPurchaseEntitlements?.length]);

    const playerInvoices = useMemo(() => {
        return (
            playerPurchaseEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [playerPurchaseEntitlements]);

    const playerEmailRedemptions = useMemo(() => {
        return playerEmailEntitlements?.flatMap(
            (entitlement) => entitlement.emailRedemptions
        );
    }, [playerEmailEntitlements]);

    const playerPasswordRedemptions = useMemo(() => {
        return playerPasswordEntitlements?.flatMap(
            (entitlement) => entitlement.passwordRedemptions
        );
    }, [playerPasswordEntitlements]);

    const playlistBroadcastIsGated = useMemo(
        () => broadcastToCheck?.isGated ?? false,
        [broadcastToCheck]
    );

    const playlistBroadcastIsGatedIndividually = useMemo(
        () =>
            !!broadcastToCheck?.entitlements?.length &&
            broadcastToCheck?.entitlements.length > 0,
        [broadcastToCheck]
    );

    const playlistBroadcastIsEntitled = useMemo(
        () => broadcastToCheck?.isEntitled ?? false,
        [broadcastToCheck]
    );

    const playlistBroadcastRequiresPurchase = useMemo(
        () =>
            playlistBroadcastIsGatedIndividually &&
            !playlistBroadcastIsEntitled,
        [playlistBroadcastIsEntitled, playlistBroadcastIsGatedIndividually]
    );

    const hasFeaturedVideoAndIdleStateIsFeatured = useMemo<boolean>(
        () =>
            (collection?.details?.idleState === "SelectVideo" &&
                broadcastToCheck?.broadcast?.details?.id ===
                    collection?.details?.idleBroadcastId) ??
            false,
        [collection, broadcastToCheck]
    );

    const playlistBroadcastPurchaseEntitlements = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            broadcastToCheck?.entitlements?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Video" &&
                    entitlement?.details?.redemptionType === "Purchase"
            ) ?? []
        );
    }, [
        broadcastToCheck?.entitlements,
        hasFeaturedVideoAndIdleStateIsFeatured
    ]);

    const playlistBroadcastEmailEntitlements = useMemo(() => {
        if (
            hasFeaturedVideoAndIdleStateIsFeatured ||
            playlistBroadcastPurchaseEntitlements?.length > 0
        )
            return [];
        return (
            broadcastToCheck?.entitlements?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Video" &&
                    entitlement?.details?.redemptionType === "Email"
            ) ?? []
        );
    }, [
        broadcastToCheck?.entitlements,
        hasFeaturedVideoAndIdleStateIsFeatured,
        playlistBroadcastPurchaseEntitlements?.length
    ]);

    const playlistBroadcastPasswordEntitlements = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            broadcastToCheck?.entitlements?.filter(
                (entitlement) =>
                    entitlement?.details?.type === "Video" &&
                    entitlement?.details?.redemptionType === "Password"
            ) ?? []
        );
    }, [
        broadcastToCheck?.entitlements,
        hasFeaturedVideoAndIdleStateIsFeatured
    ]);

    const playlistBroadcastInvoices = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            playlistBroadcastPurchaseEntitlements
                ?.flatMap((entitlement) => entitlement.purchases)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [
        playlistBroadcastPurchaseEntitlements,
        hasFeaturedVideoAndIdleStateIsFeatured
    ]);

    const playlistBroadcastEmailRedemptions = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            playlistBroadcastEmailEntitlements
                ?.flatMap((entitlement) => entitlement.emailRedemptions)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [
        hasFeaturedVideoAndIdleStateIsFeatured,
        playlistBroadcastEmailEntitlements
    ]);

    const playlistBroadcastPasswordRedemptions = useMemo(() => {
        if (hasFeaturedVideoAndIdleStateIsFeatured) return [];
        return (
            playlistBroadcastPasswordEntitlements
                ?.flatMap((entitlement) => entitlement.passwordRedemptions)
                ?.filter((invoice) => !!invoice) ?? []
        );
    }, [
        hasFeaturedVideoAndIdleStateIsFeatured,
        playlistBroadcastPasswordEntitlements
    ]);

    const creator = useSelector((s: RootState) => s.playerCreator);
    const creatorCustomers = useSelector((s: RootState) => s.creatorCustomers);
    const customerEmail = useMemo(
        () =>
            creatorCustomers[creator?.details?.stripeAccountId ?? ""]?.ticket
                ?.email,
        [creator, creatorCustomers]
    );
    const gatedContentDisabled = useMemo<boolean>(
        () => isEmbed && !parentFrame,
        [isEmbed, parentFrame]
    );

    const userHasSubscription = useMemo<boolean>(() => {
        return catalogInvoices?.length > 0 || playerInvoices?.length > 0;
    }, [catalogInvoices, playerInvoices]);

    const {
        availablePurchaseEntitlements,
        availableEmailEntitlements,
        availablePasswordEntitlements
    } = useMemo(() => {
        if (catalogInvoices?.length > 0) {
            return {
                availablePurchaseEntitlements: [],
                availableEmailEntitlements: [],
                availablePasswordEntitlements: []
            };
        }
        if (
            catalogEmailRedemptions?.length > 0 ||
            catalogPasswordRedemptions?.length > 0
        ) {
            return {
                availableEmailEntitlements: [],
                availablePasswordEntitlements: [],
                availablePurchaseEntitlements: catalogPurchaseEntitlements
            };
        }
        if (playerInvoices?.length > 0) {
            return {
                availablePurchaseEntitlements: catalogPurchaseEntitlements,
                availableEmailEntitlements: catalogEmailEntitlements,
                availablePasswordEntitlements: catalogPasswordEntitlements
            };
        }
        if (
            playerEmailRedemptions?.length > 0 ||
            playerPasswordRedemptions?.length > 0
        ) {
            return {
                availableEmailEntitlements: catalogEmailEntitlements,
                availablePasswordEntitlements: catalogPasswordEntitlements,
                availablePurchaseEntitlements: [
                    ...catalogPurchaseEntitlements,
                    ...playerPurchaseEntitlements
                ]
            };
        }
        if (playlistBroadcastInvoices?.length > 0) {
            return {
                availablePurchaseEntitlements: [
                    ...catalogPurchaseEntitlements,
                    ...playerPurchaseEntitlements
                ],
                availableEmailEntitlements: [
                    ...catalogEmailEntitlements,
                    ...playerEmailEntitlements
                ],
                availablePasswordEntitlements: [
                    ...catalogPasswordEntitlements,
                    ...playerPasswordEntitlements
                ]
            };
        }
        if (
            playlistBroadcastEmailRedemptions?.length > 0 ||
            playlistBroadcastPasswordRedemptions?.length > 0
        ) {
            return {
                availablePurchaseEntitlements: [
                    ...catalogPurchaseEntitlements,
                    ...playerPurchaseEntitlements,
                    ...playlistBroadcastPurchaseEntitlements
                ],
                availableEmailEntitlements: [
                    ...catalogEmailEntitlements,
                    ...playerEmailEntitlements
                ],
                availablePasswordEntitlements: [
                    ...catalogPasswordEntitlements,
                    ...playerPasswordEntitlements
                ]
            };
        }
        return {
            availablePurchaseEntitlements: [
                ...catalogPurchaseEntitlements,
                ...playerPurchaseEntitlements,
                ...playlistBroadcastPurchaseEntitlements
            ],
            availableEmailEntitlements: [
                ...catalogEmailEntitlements,
                ...playerEmailEntitlements,
                ...playlistBroadcastEmailEntitlements
            ],
            availablePasswordEntitlements: [
                ...catalogPasswordEntitlements,
                ...playerPasswordEntitlements,
                ...playlistBroadcastPasswordEntitlements
            ]
        };
    }, [
        catalogInvoices,
        catalogEmailRedemptions,
        catalogPasswordRedemptions,
        playerInvoices,
        playerEmailRedemptions,
        playerPasswordRedemptions,
        playlistBroadcastInvoices,
        playlistBroadcastEmailRedemptions,
        playlistBroadcastPasswordRedemptions,
        catalogPurchaseEntitlements,
        playerPurchaseEntitlements,
        playlistBroadcastPurchaseEntitlements,
        catalogEmailEntitlements,
        playerEmailEntitlements,
        playlistBroadcastEmailEntitlements,
        catalogPasswordEntitlements,
        playerPasswordEntitlements,
        playlistBroadcastPasswordEntitlements
    ]);

    const availableEntitlements = useMemo(() => {
        return [
            ...availablePurchaseEntitlements,
            ...availableEmailEntitlements,
            ...availablePasswordEntitlements
        ];
    }, [
        availablePurchaseEntitlements,
        availableEmailEntitlements,
        availablePasswordEntitlements
    ]);

    return {
        catalogIsGated,
        catalogIsEntitled,
        /** Indicates that the catalog is gated and has not been purchased */
        catalogRequiresPurchase,
        catalogPurchaseEntitlements,
        catalogEmailEntitlements,
        catalogPasswordEntitlements,
        catalogInvoices,
        catalogEmailRedemptions,
        catalogPasswordRedemptions,
        playerIsGated,
        playerIsEntitled,
        /** Indicates that the player is gated and has not been purchased */
        playerRequiresPurchase,
        playerPurchaseEntitlements,
        playerEmailEntitlements,
        playerPasswordEntitlements,
        playerInvoices,
        playerEmailRedemptions,
        playerPasswordRedemptions,
        playlistBroadcastIsGated,
        /** Indicates that the broadcast is gated and has not been purchased */
        playlistBroadcastRequiresPurchase,
        /** Broadcast is gated from its own entitlement, not including player entitlements */
        playlistBroadcastIsGatedIndividually,
        playlistBroadcastIsEntitled,
        playlistBroadcastPurchaseEntitlements,
        playlistBroadcastEmailEntitlements,
        playlistBroadcastPasswordEntitlements,
        playlistBroadcastInvoices,
        playlistBroadcastEmailRedemptions,
        playlistBroadcastPasswordRedemptions,
        customerEmail,
        gatedContentDisabled,
        userHasSubscription,
        availableEmailEntitlements,
        availablePasswordEntitlements,
        availablePurchaseEntitlements,
        availableEntitlements
    };
};
