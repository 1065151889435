export enum Modals {
    None = "none",
    Report = "report",
    DMCA = "dmca",
    PurchaseOptions = "purchase-options",
    RestorePurchase = "restore-purchase",
    PurchaseRestored = "purchase-restored",
    PurchaseFoundAndRestored = "purchase-found-and-restored",
    StripeCheckout = "stripe-checkout",
    EmailAccess = "email-access",
    PasswordAccess = "password-access"
}

export interface ModalPayload {
    /**
     * unique identifier for modals that can be duplicated (like the video upload session modal)
     * for modals that are not duplicated, id and type will be the same
     */
    id: Modals | string;
    type: Modals;
    component?: JSX.Element;
}
export interface ModalsState {
    /** Either the enum value for a modal to open or the modal itself */
    activeModal: ModalPayload;
    modalData?: any;
}
