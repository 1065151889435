import { useState, useCallback, useEffect } from "react";
import { ProductMetaDataDetailsResponseMetaDataResponse } from "@switcherstudio/player-api-client"; // Replace with the correct import path for MetaDataResponse
import { client } from "api/playerClient"; // Adjust this import according to your project structure

export const useGetMetaData = (productIds: string[]) => {
    const [metadata, setMetadata] =
        useState<ProductMetaDataDetailsResponseMetaDataResponse>();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(undefined);

    const getMetaData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const meta = await client.meta(productIds);
            setMetadata(meta);
            return meta;
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [productIds]);

    useEffect(() => {
        if (!!productIds && productIds.length > 0) {
            getMetaData();
        }
    }, [getMetaData, productIds]);

    return { metadata, loading, error, getMetaData };
};
