/**
 * A helper to find the greater of two numbers
 * @param a a number
 * @param b a number
 * @returns the greater of the two numbers
 */
export const greater = (a: number, b: number) => (a > b ? a : b);

/**
 * A helper to find the least of two numbers
 * @param a a number
 * @param b a number
 * @returns the lesser of the two numbers
 */
export const lesser = (a: number, b: number) => (a < b ? a : b);
