import { displayAmount } from "helpers/currency";
import {
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../Button";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import {
    EntitlementPriceDetailsResponse,
    EntitlementPriceResponse,
    EntitlementResponse
} from "@switcherstudio/player-api-client";
import { useHandlePurchasePass } from "hooks/useHandlePurchasePass";
import { validEmail } from "helpers/email";
const cx = classNames.bind(styles);

export type ModalStatus = "buy" | "restore" | undefined;

export interface RequestAccessButtonGroupProps {
    entitlement: EntitlementResponse | undefined;
    disabled?: boolean;
    entityId: string | undefined;
    email: string;
    setError?: (error: string) => void;
}

export const RequestAccessButtonGroup: React.FC<
    RequestAccessButtonGroupProps
> = ({ entitlement, disabled, entityId, email, setError }) => {
    const { t } = useTranslation();
    const [selectedPrice, setSelectedPrice] = useState<
        EntitlementPriceResponse | undefined
    >();
    const { handlePurchase, hasPurchaseError } = useHandlePurchasePass(
        entitlement?.product,
        selectedPrice,
        entityId,
        entitlement as EntitlementResponse
    );
    const isValidEmail = validEmail(email ?? "");

    const getDisplayAmount = useCallback(
        (price: EntitlementPriceDetailsResponse | undefined) => {
            const priceString = price?.amount
                ? displayAmount(price.amount, { signed: true, compact: true })
                : "$0";
            return price?.isRecurring
                ? `${priceString}/${price?.purchaseInterval?.replace(
                      /ly$/,
                      ""
                  )}`
                : t("buttons:request-access", { amount: priceString });
        },
        [t]
    );

    const pricesSorted = useMemo(() => {
        if (!entitlement?.prices) return [];
        return [...entitlement?.prices].sort((priceA, priceB) =>
            (priceA.details?.amount ?? 0) > (priceB.details?.amount ?? 0)
                ? 1
                : -1
        );
    }, [entitlement]);

    useEffect(() => {
        if (selectedPrice) {
            handlePurchase(email);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPrice]);

    useEffect(() => {
        if (hasPurchaseError) {
            setError?.(t("purchase-options:purchase-error"));
            setSelectedPrice(undefined);
        }
    }, [hasPurchaseError]);

    const handleClick = (
        price: SetStateAction<EntitlementPriceResponse | undefined>
    ) => {
        if (!isValidEmail || email === "") {
            setError?.(t("purchase-options:invalid-email"));
        } else {
            setError?.("");
            setSelectedPrice(price);
        }
    };

    return (
        <div className={cx("container")}>
            {pricesSorted?.map((price) => {
                return (
                    <Button
                        key={price.details?.id}
                        text={getDisplayAmount(price.details)}
                        type={"button"}
                        disabled={disabled}
                        onClick={() => {
                            handleClick(price);
                        }}
                    />
                );
            })}
        </div>
    );
};
