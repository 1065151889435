import React, { useMemo } from "react";
import styles from "./index.module.scss";
import SuccessIcon from "assets/icons/success.svg?react";
import ErrorIcon from "assets/icons/error.svg?react";
import { Processing } from "components/Processing";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

interface TransitionPageContentProps {
    type: "loading" | "success" | "error";
    header: string;
    descriptionLines: string[];
    extraContent?: JSX.Element;
    variant?: "page" | "modal";
}

export const TransitionPageContent = ({
    type,
    header,
    descriptionLines,
    extraContent,
    variant = "page"
}: TransitionPageContentProps) => {
    const icon = useMemo(() => {
        switch (type) {
            case "loading":
                return <Processing />;
            case "success":
                return <SuccessIcon className={cx("status-icon")} />;
            case "error":
                return <ErrorIcon className={cx("status-icon")} />;
            default:
                return <></>;
        }
    }, [type]);

    return (
        <div className={cx("transition-page", variant)}>
            {icon}
            <h4>{header}</h4>
            {descriptionLines?.map((description, index) => (
                <p key={index}>{description}</p>
            ))}
            {Boolean(extraContent) && extraContent}
        </div>
    );
};
