import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    CatalogResponse,
    CollectionResponse,
    CollectionsPagedResponse,
    CollectionVideosPagedResponse,
    CollectionVideosResponse
} from "@switcherstudio/player-api-client";
import { RootState } from "store/reducers";
import { AppDispatch } from "store/store";
import { client } from "api/playerClient";
import {
    DEFAULT_COLLECTION_VIDEO_PAGE_SIZE,
    DEFAULT_COLLECTIONS_PAGE_SIZE
} from "./slice";
import { IFrameType } from "store/VideoSession/types";

/**
 * Fetches the catalog data from the API and optionally loads associated collections.
 */
export const getCatalog = createAsyncThunk<
    CatalogResponse,
    { catalogId: string; loadCollections?: boolean },
    { state: RootState; dispatch: AppDispatch }
>(
    "catalogState/getCatalogState",
    async ({ catalogId, loadCollections = true }, { dispatch, getState }) => {
        const catalog = client.catalog(catalogId);

        if (loadCollections) {
            const currentPage = getState().catalogState.collections.page ?? 0;

            if (
                // The current page is the same as the total loadedCollectionPageCount
                getState().catalogState.loadedCollectionPageCount ===
                currentPage
            ) {
                // Then we want to load the next page
                dispatch(getNextCollections({ catalogId }));
            } else {
                // Loop through collections from the current loaded page to the loadedCollectionPageCount. Happens when collections are reloaded after purchase.
                for (
                    let pageNumber = currentPage;
                    pageNumber <=
                    getState().catalogState.loadedCollectionPageCount;
                    pageNumber++
                ) {
                    dispatch(getCollections({ catalogId, pageNumber }));
                }
            }
        }

        return catalog;
    }
);

/**
 * Gets at a given page number.
 */
export const getCollections = createAsyncThunk<
    CollectionsPagedResponse | void,
    {
        catalogId: string;
        pageSize?: number;
        pageNumber?: number;
    },
    { state: RootState; dispatch: AppDispatch }
>(
    "catalogState/getCollectionsState",
    async (
        { catalogId, pageSize = DEFAULT_COLLECTIONS_PAGE_SIZE, pageNumber },
        { dispatch, getState }
    ) => {
        pageNumber ??= getState().catalogState.collections.page ?? 0;

        if (pageNumber === 0) return;

        const collections = await client.collections(
            catalogId,
            pageNumber,
            pageSize
        );
        const hasOneCollection: boolean = collections.collections?.length === 1;

        collections.collections?.forEach((collection) => {
            const collectionId = collection.details?.id ?? "";
            const isGrid: boolean =
                collection.details?.embeddedDisplay === "Grid";
            /** if user displays only one collection in grid mode in catalog, show up to 23 videos on initial load */
            const largeInitialGridPage =
                pageNumber === 0 && hasOneCollection && isGrid;

            dispatch(
                getCollectionVideos({
                    collectionId,
                    isInCatalog: true,
                    pageSize: largeInitialGridPage ? 23 : undefined
                })
            );
            dispatch(getNextEvents({ collectionId }));
        });

        return collections;
    }
);

/** Gets the next page from the loaded collections, incrementing + 1 from the current page. */
export const getNextCollections = createAsyncThunk<
    CollectionsPagedResponse | void,
    | {
          catalogId?: string;
          pageSize?: number;
      }
    | undefined,
    { state: RootState; dispatch: AppDispatch }
>(
    "catalogState/getNextCollections",
    async (
        { catalogId, pageSize } = {
            pageSize: DEFAULT_COLLECTIONS_PAGE_SIZE
        },
        { dispatch, getState }
    ) => {
        catalogId ??= getState().catalogState.catalog.details?.id;

        if (catalogId) {
            dispatch(
                getCollections({
                    catalogId,
                    pageSize,
                    pageNumber:
                        (getState().catalogState.collections.page ?? 0) + 1
                })
            );
        }
    }
);

export const getCollection = createAsyncThunk<
    CollectionResponse,
    {
        /** The Collection id to fetch. */
        collectionId: string;
        /** Injects trailer into the playlist. Used in thunk. */
        shouldInjectTrailer?: boolean;
    },
    { state: RootState; dispatch: AppDispatch }
>(
    "catalogState/getCollectionState",
    async ({ collectionId }, { dispatch, getState }) => {
        const isInCatalog = !!getState().catalogState.configuredCatalogId;
        const collection = await client.collections2(collectionId);
        const shouldPage =
            getState().videoSession.iframeType === IFrameType.Main &&
            collection.details?.embeddedDisplay !== "DefaultThumbnail";
        dispatch(
            getCollectionVideos({
                collectionId,
                isInCatalog,
                pageNumber: 1,
                pageSize: shouldPage ? 23 : 9999
            })
        );
        // Load the next events only if the catalog view and in the main iframe
        if (
            collection?.details?.embeddedDisplay !== "DefaultThumbnail" &&
            getState().videoSession?.iframeType === IFrameType.Main
        ) {
            dispatch(getNextEvents({ collectionId }));
        }
        return collection;
    }
);

export const getCollectionVideos = createAsyncThunk<
    CollectionVideosPagedResponse,
    {
        collectionId: string;
        isInCatalog?: boolean | undefined;
        pageNumber?: number;
        pageSize?: number;
    },
    { state: RootState; dispatch: AppDispatch }
>(
    "catalogState/getCollectionVideosState",
    async (
        {
            collectionId,
            isInCatalog = false,
            pageSize = DEFAULT_COLLECTION_VIDEO_PAGE_SIZE
        },
        { getState }
    ) => {
        const collectionVideosMap = getState().catalogState.collectionVideosMap;

        return client.collectionVideos(
            collectionId,
            isInCatalog,
            (collectionVideosMap?.[collectionId]?.page ?? 0) + 1,
            pageSize
        );
    }
);

export const getNextEvents = createAsyncThunk<
    CollectionVideosResponse,
    {
        collectionId: string;
    },
    { state: RootState; dispatch: AppDispatch }
>("catalogState/getNextEventsState", async ({ collectionId }) => {
    return await client.nextEvents(collectionId);
});
