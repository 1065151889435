import { useClickOutside } from "hooks/useClickOutside";
import { useKeyPress } from "hooks/useKeyPress";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeConfirmation } from "store/Confirmation/slice";
import { RootState } from "store/reducers";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export const ConfirmationModal = () => {
    const {
        htmlMessage,
        message,
        titleText,
        cancelText,
        confirmText,
        onSuccess,
        onCancel
    } = useSelector((state: RootState) => state.confirmation);
    const dispatch = useDispatch();
    const modalRef = useRef(null);
    useClickOutside(modalRef, close);
    useKeyPress("Escape", close);

    const executeCallback = async () => {
        if (typeof onSuccess === "function") {
            dispatch(closeConfirmation());
            return onSuccess();
        }
    };

    function close() {
        dispatch(closeConfirmation());
        if (typeof onCancel === "function") {
            return onCancel();
        }
    }

    return (
        <>
            <div className={cx("modal-wrapper")}>
                <div className={cx("modal-container")} ref={modalRef}>
                    {titleText && (
                        <div className={cx("modal-header")}>{titleText}</div>
                    )}
                    <div className={cx("modal-body")}>
                        {htmlMessage ? (
                            <div
                                dangerouslySetInnerHTML={{ __html: message }}
                            />
                        ) : (
                            <p>{message}</p>
                        )}
                    </div>
                    <div className={cx("modal-footer")}>
                        <button
                            className="btn btn-primary"
                            onClick={executeCallback}
                        >
                            {confirmText}
                        </button>
                        <button className="btn btn-text" onClick={close}>
                            {cancelText}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
