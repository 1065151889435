/**
 * https://css-tricks.com/lets-create-a-lightweight-native-event-bus-in-javascript/#aa-an-example
 */

class EventBus<DetailType = any> {
    private eventTarget: EventTarget;
    constructor(description = "") {
        this.eventTarget = document.appendChild(
            document.createComment(description)
        );
    }
    on(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        const wrappedListener: EventListener = (evt) =>
            listener(evt as CustomEvent<DetailType>);
        this.eventTarget.addEventListener(type, wrappedListener);
    }
    once(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        const wrappedListener: EventListener = (evt) =>
            listener(evt as CustomEvent<DetailType>);
        this.eventTarget.addEventListener(type, wrappedListener, {
            once: true
        });
    }
    off(type: string, listener: (event: CustomEvent<DetailType>) => void) {
        const wrappedListener: EventListener = (evt) =>
            listener(evt as CustomEvent<DetailType>);
        this.eventTarget.removeEventListener(type, wrappedListener);
    }
    emit(type: string, detail?: DetailType) {
        return this.eventTarget.dispatchEvent(
            new CustomEvent(type, { detail })
        );
    }
}

export const eventBus = new EventBus("SwitcherEmbeddedEventBus");
