import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/reducers";
import { useRedirectUrl } from "./useRedirectUrl";
import { setActiveModal } from "store/Modals/slice";
import { Modals } from "store/Modals/types";
import { AppDispatch } from "../store/store";
import { setIsLoading } from "store/Loading/slice";
import { StripeCheckoutModal } from "components/Modals/StripeCheckoutModal";
import { PurchaseSuccessModal } from "components/Modals/PurchaseSuccessModal";
import {
    EntitlementPriceResponse,
    EntitlementProductResponse,
    EntitlementResponse
} from "@switcherstudio/player-api-client";
import { payments } from "api/payment/client";
import useCollectionWithVideos from "./useCollectionWithVideos";

export const useHandlePurchasePass = (
    product: EntitlementProductResponse | undefined,
    selectedPrice: EntitlementPriceResponse | undefined,
    entitlementResponse: EntitlementResponse
) => {
    const { details } = useSelector((s: RootState) => s.playerCreator);
    const { catalog } = useSelector((s: RootState) => s.catalogState);
    const { collection } = useCollectionWithVideos();
    const { currentCollectionVideo } = useSelector(
        (s: RootState) => s.videoSession
    );
    const [hasPurchaseError, setHasPurchaseError] = useState<boolean>(false);

    const dispatch = useDispatch<AppDispatch>();

    const entityDetails = useMemo(() => {
        switch (entitlementResponse?.details?.type) {
            case "Catalog":
                return {
                    entityId: catalog?.details?.id ?? "",
                    entityName: catalog?.details?.title ?? ""
                };
            case "Collection":
                return {
                    entityId: collection?.details?.id,
                    entityName: collection?.details?.name
                };
            case "Video":
                return {
                    entityId: currentCollectionVideo?.broadcast?.details?.id,
                    entityName:
                        currentCollectionVideo?.broadcast?.details?.title
                };
        }
    }, [catalog, collection, currentCollectionVideo, entitlementResponse]);

    const redirectUrl = useRedirectUrl();

    const createCheckout = useCallback(
        async (email: string) => {
            try {
                const checkoutSessionResponse =
                    await payments.CreateStripeCheckout({
                        EmailAddress: email,
                        PriceId: selectedPrice?.details?.id,
                        RedirectUrl: redirectUrl.href,
                        IsEmbeddedCheckout: true,
                        IsEmbed: true, // we are always in an embed context now.
                        Metadata: {
                            EntityType: entitlementResponse?.details?.type,
                            EntityId: entityDetails?.entityId,
                            EntityName: entityDetails?.entityName,
                            ProjectId: details?.projectId
                        }
                    });

                if (!checkoutSessionResponse.StripeClientSecret) {
                    throw new Error("StripeClientSecret is not defined");
                }

                dispatch(
                    setActiveModal({
                        id: Modals.StripeCheckout,
                        type: Modals.StripeCheckout,
                        component: (
                            <StripeCheckoutModal
                                stripeCheckoutSecret={
                                    checkoutSessionResponse.StripeClientSecret
                                }
                                stripeAccountId={details?.stripeAccountId ?? ""}
                                checkoutSessionId={
                                    checkoutSessionResponse.CheckoutSessionId
                                }
                                creatorStripeAccountId={
                                    checkoutSessionResponse.CreatorStripeId
                                }
                            />
                        )
                    })
                );

                dispatch(setIsLoading(false));
            } catch {
                dispatch(setIsLoading(false));
                setHasPurchaseError(true);
            }
        },
        [
            selectedPrice?.details?.id,
            redirectUrl.href,
            dispatch,
            details,
            entitlementResponse,
            entityDetails
        ]
    );

    const tryRetrievePurchaseOrCheckout = useCallback(
        async (email: string) => {
            try {
                setHasPurchaseError(false);
                dispatch(setIsLoading(true));
                await payments.RestorePurchase({
                    EmailAddress: email,
                    ProductIds: [product?.details?.id ?? ""],
                    RedirectUrl: redirectUrl.href,
                    IsEmbed: true // we are always in an embed context now.
                });

                dispatch(
                    setActiveModal({
                        id: Modals.PurchaseFoundAndRestored,
                        type: Modals.PurchaseFoundAndRestored,
                        component: (
                            <PurchaseSuccessModal
                                activeModal={Modals.PurchaseFoundAndRestored}
                                customerEmail={email}
                            />
                        )
                    })
                );
            } catch (e) {
                createCheckout(email);
            }
            dispatch(setIsLoading(false));
        },
        [createCheckout, dispatch, product?.details?.id, redirectUrl.href]
    );

    return {
        handlePurchase: tryRetrievePurchaseOrCheckout,
        hasPurchaseError
    };
};
