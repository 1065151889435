import { createTransform } from "redux-persist";
import { CreatorCustomersState, PasswordClaim } from "./types";

const filterExpiredPasswordClaims = (passwordClaims: PasswordClaim[]) => {
    const expiration = 604800000; // 7 days in milliseconds

    const now = Date.now();
    return passwordClaims.filter((claim) => {
        const dateRedeemed = new Date(claim.DateRedeemed).getTime();
        return now - dateRedeemed < expiration;
    });
};

const PasswordClaimsTransform = createTransform(
    // transform state being rehydrated
    (outboundState: CreatorCustomersState) => {
        const transformedState = { ...outboundState };
        Object.keys(transformedState).forEach((creatorProjectId) => {
            const profile = transformedState[creatorProjectId];
            if (profile.passwordClaims) {
                const updatedPasswordClaims = filterExpiredPasswordClaims(
                    profile.passwordClaims.map((claim: PasswordClaim) => ({
                        ...claim,
                        DateRedeemed: new Date(
                            claim.DateRedeemed as unknown as string
                        )
                    }))
                );

                const updatedProfile = {
                    ...profile,
                    passwordClaims: updatedPasswordClaims
                };

                transformedState[creatorProjectId] = updatedProfile;
            }
        });
        return transformedState;
    }
);

export default PasswordClaimsTransform;
