import { EntitlementType } from "@switcherstudio/player-api-client";
import paymentFetch from "./helpers";

export interface GetStripeCheckoutStatusFields {
    sessionId: string;
    creatorStripeAccountId: string;
}

export interface GetSessionPortalFields {
    stripeAccountId: string;
    customerId: string;
    subscriptionId: string;
}

export interface StripeCheckoutStatusResponse {
    /** The checkout session status. Could */
    Status?: string | undefined;
    /** The gated content authorization token */
    AuthToken?: string | undefined;
}

/** The creator checkout request. */
export interface CreatorCheckoutRequest {
    /** Gets or Sets the price id. */
    PriceId?: string | undefined;
    /** Gets or Sets the redirect url. */
    RedirectUrl?: string | undefined;
    /** Gets or Sets the email address. */
    EmailAddress?: string | undefined;
    /** Gets or Sets is embedded checkout. */
    IsEmbeddedCheckout?: boolean | undefined;
    /** Gets or Sets the is embed. */
    IsEmbed?: boolean | undefined;
    /** Gets or sets creator checkout request metadata. */
    Metadata?: CreatorCheckoutMetadata | undefined;
}

export interface CreatorCheckoutMetadata {
    /** Gets or sets the id GUID, as a string, of the entity that the request originated from. */
    EntityId?: string | undefined;
    /** Gets or sets the type of the entity from whence the request originated. */
    EntityType?: EntitlementType | undefined;
    /** Gets or sets the name of the entity that the request originated from. */
    EntityName?: string | undefined;
    /** Gets of sets the project id */
    ProjectId?: string | undefined;
}

/** The creator checkout request. */
export interface RestorePurchaseRequest {
    /** Gets or Sets the Product id. */
    ProductId?: string | undefined;
    /** Gets or Sets the Product ids. */
    ProductIds?: string[] | undefined;
    /** Gets or Sets the email address. */
    EmailAddress?: string | undefined;
    /** Gets or Sets the redirect url. */
    RedirectUrl?: string | undefined;
    /** Gets or Sets whether request is coming from embed or not. */
    IsEmbed?: boolean | undefined;
    /** Gets or Sets stripeAccountId for product lookup. */
    StripeAccountId?: string | undefined;
}

/** The creator checkout response. */
export interface CreatorCheckoutResponse {
    StripeCheckoutUrl?: string | undefined;
    StripeClientSecret?: string | undefined;
    CreatorStripeId?: string | undefined;
    CheckoutSessionId?: string | undefined;
}
export interface CreatorCustomerSubscriptionPortalResponse {
    Url?: string | undefined;
    ForSubscription?: string | undefined;
    readonly RenewUrl?: string | undefined;
    readonly UpdateUrl?: string | undefined;
    readonly CancelUrl?: string | undefined;
}

export interface EmailAccessRequest {
    Email: string | undefined;
    EntityId: string | undefined;
    EntityType: string | undefined;
    EntityName: string | undefined;
}

export interface EmailAccessResponse {
    AuthToken: string | undefined;
}

export const payments = {
    GetStripeCheckoutStatus: (
        fields: GetStripeCheckoutStatusFields
    ): Promise<StripeCheckoutStatusResponse> =>
        paymentFetch({
            resource: "/api/CreatorCheckout/StripeCheckoutStatus",
            fields
        }),
    GetSessionPortal: ({
        stripeAccountId,
        customerId,
        subscriptionId
    }: GetSessionPortalFields): Promise<CreatorCustomerSubscriptionPortalResponse> =>
        paymentFetch({
            resource: `/api/StripeAccount/${stripeAccountId}/CreatorCustomers/${customerId}/Subscription/${subscriptionId}/Portal`
        }),
    CreateStripeCheckout: (
        body: CreatorCheckoutRequest
    ): Promise<CreatorCheckoutResponse> =>
        paymentFetch({
            method: "POST",
            resource: "/api/CreatorCheckout/CreateStripeCheckout",
            body
        }),
    RestorePurchase: (
        body: RestorePurchaseRequest
    ): Promise<CreatorCheckoutResponse> =>
        paymentFetch({
            method: "POST",
            resource: "/api/CreatorCheckout/RestorePurchase",
            body
        }),
    CreateOrValidateEmailAccess: (
        body: EmailAccessRequest
    ): Promise<EmailAccessResponse> =>
        paymentFetch({
            method: "POST",
            resource: "/api/EmailAccess",
            body
        })
};
