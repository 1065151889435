import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ModalsState, Modals, ModalPayload } from "./types";

const initialState: ModalsState = {
    activeModal: { id: Modals.None, type: Modals.None }
};

export const modals = createSlice({
    name: "modals",
    initialState: initialState,
    reducers: {
        setActiveModal: (
            state,
            { payload }: PayloadAction<Modals | ModalPayload>
        ) => {
            const prevActiveModal = state.activeModal;

            const isModalPayload = (
                payload: Modals | ModalPayload
            ): payload is ModalPayload => {
                return (payload as ModalPayload)?.type !== undefined;
            };

            return {
                ...state,
                activeModal: isModalPayload(payload)
                    ? payload
                    : { id: payload, type: payload },
                previousModal: prevActiveModal
            };
        },
        setModalData: (state, { payload }: PayloadAction<any>) => {
            return {
                ...state,
                modalData: payload
            };
        }
    }
});

export const { setActiveModal, setModalData } = modals.actions;

export default modals.reducer;
