import store from "../store/store";

export function initSegment() {
    // @ts-expect-error This function is not defined on the types
    analytics.addSourceMiddleware(Middleware);
    analytics.load(import.meta.env.VITE_SEGMENT_WRITEKEY as string, {});
}

export function trackSegmentEvent(
    event: string,
    properties?: any,
    options?: any
) {
    const promise = new Promise<void>((resolve) => {
        analytics.track(event, properties, options, () => resolve());
    });

    return promise;
}

export function trackSegmentPage(path: any, options: any) {
    analytics.page(path, options);
}

export function getAnonymousId() {
    if (typeof analytics.user === "function")
        return analytics.user().anonymousId();
}

export function pathToPage(pathname: string) {
    const word = pathname.split("/")[1];
    return word.slice(0, 1).toLocaleUpperCase() + word.slice(1);
}

const Middleware = function ({ payload, next }: any) {
    const state = store.getState();
    const doNotTrack = state.consentTracking.doNotTrack;
    const playerCreatorStripeAccountId =
        state.playerCreator?.details?.stripeAccountId;
    const creatorCustomerId =
        state.creatorCustomers[playerCreatorStripeAccountId ?? ""]?.ticket
            ?.customerId;

    if (doNotTrack) {
        payload.obj.integrations = {
            "Google Analytics 4": false
        };
    }

    if (creatorCustomerId) {
        payload.obj.properties.creatorCustomerId = creatorCustomerId;
    }

    next(payload);
};
