import shopping from "./shopping/slice";
import videoSession from "./VideoSession/slice";
// import brandProfileState from "./BrandProfile/slice";
import notifications from "./notification/slice";
import confirmation from "./Confirmation/slice";
import { combineReducers } from "@reduxjs/toolkit";
import consentTracking from "./ConsentTracking/slice";
import creatorCustomers from "./CreatorCustomers/slice";
import playerCreator from "./PlayerCreator/slice";
import modals from "./Modals/slice";
import loading from "./Loading/slice";
import mobileDebugLogger from "./MobileDebug/slice";
import catalogState from "./Catalog/slice";

const rootReducer = combineReducers({
    //   brandProfileState,
    shopping,
    notifications,
    videoSession,
    confirmation,
    consentTracking,
    creatorCustomers,
    playerCreator,
    modals,
    loading,
    mobileDebugLogger,
    catalogState
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
