import { loadStripe, Stripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckout,
    EmbeddedCheckoutProvider
} from "@stripe/react-stripe-js";
import { GenericModal } from "../GenericModal";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { exchangeCode } from "store/CreatorCustomers/thunks";
import { AppDispatch } from "store/store";
import { RootState } from "store/reducers";
import { setIsIframeVisible } from "store/VideoSession/slice";
import { IFrameType } from "store/VideoSession/types";
import { setActiveModal } from "store/Modals/slice";
import { Modals } from "store/Modals/types";
import { payments } from "api/payment/client";

const STRIPE_CHECKOUT_POLLING_INTERVAL =
    parseInt(import.meta.env.VITE_STRIPE_CHECKOUT_POLLING_INTERVAL || "") ||
    2000;

interface StripeCheckoutModalProps {
    stripeCheckoutSecret: string;
    stripeAccountId: string;
    checkoutSessionId: string | undefined;
    creatorStripeAccountId: string | undefined;
}

export const StripeCheckoutModal: React.FC<StripeCheckoutModalProps> = ({
    stripeCheckoutSecret,
    stripeAccountId,
    checkoutSessionId,
    creatorStripeAccountId
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();

    const [status, setStatus] = useState<
        "processing" | "processing-error" | "authorizing" | "authorizing-error"
    >("processing");
    const [showContinue, setShowContinue] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);

    const [stripe, setStripe] = useState<Stripe | null>(null);

    const { iframeType, parentFrame } = useSelector(
        (s: RootState) => s.videoSession
    );

    useEffect(() => {
        async function initializeStripe() {
            const stripeInstance = await loadStripe(
                import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
                {
                    stripeAccount: stripeAccountId
                }
            );
            setStripe(stripeInstance);
        }

        initializeStripe();
    }, [stripeAccountId]);

    const handleOnComplete = useCallback(() => {
        setShowContinue(true);
    }, []);

    const handleClose = useCallback(() => {
        dispatch(setActiveModal(Modals.None));
        if (iframeType === IFrameType.AuxModal && parentFrame) {
            dispatch(setIsIframeVisible(false));
            parentFrame.closePurchaseModal();
        }
    }, [iframeType, parentFrame, dispatch]);

    const pollCheckoutStatus = useCallback(() => {
        setTimeout(async () => {
            if (checkoutSessionId && creatorStripeAccountId) {
                setProcessing(true);

                try {
                    const checkoutStatusResponse =
                        await payments.GetStripeCheckoutStatus({
                            sessionId: checkoutSessionId,
                            creatorStripeAccountId
                        });
                    if (
                        checkoutStatusResponse?.Status === "success" &&
                        checkoutStatusResponse?.AuthToken
                    ) {
                        setStatus("authorizing");
                        try {
                            await dispatch(
                                exchangeCode(checkoutStatusResponse.AuthToken)
                            );
                        } catch (e) {
                            setStatus("authorizing-error");
                            setProcessing(false);
                        }
                        handleClose();
                    } else {
                        pollCheckoutStatus();
                    }
                } catch (e) {
                    setStatus("processing-error");
                    setProcessing(false);
                }
            }
        }, STRIPE_CHECKOUT_POLLING_INTERVAL);
    }, [checkoutSessionId, creatorStripeAccountId, dispatch, handleClose]);

    const transitionHeader = useMemo(() => {
        switch (status) {
            case "processing":
                return t("modals:processing");
            case "processing-error":
                return t("modals:processing-error");
            case "authorizing":
                return t("modals:authorizing");
            case "authorizing-error":
                return "Something Went Wrong";
            default:
                return "";
        }
    }, [t, , status]);

    const transitionDescriptionLines = useMemo(() => {
        switch (status) {
            case "processing":
                return [t("modals:processing-description")];
            case "processing-error":
                return [t("modals:processing-error-description")];
            case "authorizing":
                return [t("modals:authorizing-description")];
            case "authorizing-error":
                return [t("modals:authorizing-error-description")];
            default:
                return [];
        }
    }, [t, status]);

    return (
        <GenericModal
            title={t("modals:checkout")}
            variant="stripe-checkout-embed"
            successButton={t("modals:continue")}
            onSuccess={() => pollCheckoutStatus()}
            onClose={handleClose}
            shouldHideButtons={!showContinue || processing}
            preventDismiss={processing}
            preventCloseOnSuccess={true}
            isProcessing={processing}
            processingHeader={transitionHeader}
            processingDescriptionLines={transitionDescriptionLines}
        >
            {stripe && (
                <EmbeddedCheckoutProvider
                    stripe={stripe}
                    options={{
                        clientSecret: stripeCheckoutSecret,
                        onComplete: handleOnComplete
                    }}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </GenericModal>
    );
};
