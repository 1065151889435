import { t } from "i18next";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    NotificationState,
    AddNotificationPayload,
    NotificationClass,
    Notification
} from "./types";

const initialState = {
    shoppingNotifications: {
        notifications: [],
        lastId: 0
    },
    interactiveNotifications: {
        notifications: [],
        lastId: 0
    },
    globalNotifications: {
        notifications: [],
        lastId: 0
    }
} as NotificationState;

export const notification = createSlice({
    name: "notification",
    initialState: initialState,
    reducers: {
        addNotification: (
            state,
            { payload }: PayloadAction<AddNotificationPayload>
        ) => {
            payload.message = t(
                payload.message,
                payload.messageOptions ? payload.messageOptions : undefined
            );
            if (payload.hasOwnProperty("clickText")) {
                payload.clickText = t(payload.clickText as string);
            }
            switch (payload.class) {
                case "shopping":
                    const lastShoppingId: number =
                        state.shoppingNotifications.lastId + 1;
                    return {
                        ...state,
                        shoppingNotifications: {
                            notifications: [
                                ...state.shoppingNotifications.notifications,
                                { ...payload, id: lastShoppingId }
                            ],
                            lastId: lastShoppingId
                        }
                    };
                case "interactives":
                    const lastInteractiveId: number =
                        state.interactiveNotifications.lastId + 1;
                    return {
                        ...state,
                        interactiveNotifications: {
                            notifications: [
                                ...state.interactiveNotifications.notifications,
                                { ...payload, id: lastInteractiveId }
                            ],
                            lastId: lastInteractiveId
                        }
                    };
                case "global":
                default:
                    const lastGlobalId: number =
                        state.globalNotifications.lastId + 1;
                    return {
                        ...state,
                        globalNotifications: {
                            notifications: [
                                ...state.globalNotifications.notifications,
                                {
                                    ...payload,
                                    id: state.globalNotifications.lastId + 1
                                }
                            ],
                            lastId: lastGlobalId
                        }
                    };
            }
        },
        removeNotification: (
            state,
            { payload }: PayloadAction<Pick<Notification, "id" | "class">>
        ) => {
            switch (payload.class) {
                case "shopping":
                    return {
                        ...state,
                        shoppingNotifications: {
                            ...state.shoppingNotifications,
                            notifications:
                                state.shoppingNotifications.notifications.filter(
                                    (n) => n.id !== payload.id
                                )
                        }
                    };
                case "interactives":
                    return {
                        ...state,
                        interactiveNotifications: {
                            ...state.interactiveNotifications,
                            notifications:
                                state.interactiveNotifications.notifications.filter(
                                    (n) => n.id !== payload.id
                                )
                        }
                    };
                case "global":
                default:
                    return {
                        ...state,
                        globalNotifications: {
                            ...state.globalNotifications,
                            notifications:
                                state.globalNotifications.notifications.filter(
                                    (n) => n.id !== payload.id
                                )
                        }
                    };
            }
        },
        resetNotifications: (
            state,
            { payload }: PayloadAction<NotificationClass | undefined>
        ) => {
            return {
                ...state,
                ...(payload === "interactives" && {
                    interactiveNotifications: { notifications: [], lastId: 0 }
                }),
                ...(payload === "shopping" && {
                    shoppingNotifications: { notifications: [], lastId: 0 }
                }),
                ...(payload === "global" && {
                    globalNotifications: { notifications: [], lastId: 0 }
                }),
                ...(!payload && initialState)
            };
        }
    }
});

export const { addNotification, removeNotification, resetNotifications } =
    notification.actions;

export default notification.reducer;
