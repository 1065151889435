export interface CreateUrlOptions {
    pathname?: string;
    searchParams?: { [key: string]: string | number | undefined } | undefined[];
    /** Flag to allow null or undefined values to be passed into search params */
    allowNull?: boolean;
}

/**
 * A wrapper to easily construct a URL object - see https://developer.mozilla.org/en-US/docs/Web/API/URL
 * @param base origin for new url - ex. "https://github.com"
 * @param options
 */
export const createUrl = (
    base: string,
    { pathname, searchParams, allowNull }: CreateUrlOptions = {
        searchParams: {},
        allowNull: false
    }
): URL => {
    let url = new URL(base);

    if (pathname) {
        url.pathname = pathname.startsWith("/") ? pathname : "/" + pathname;
    }

    for (const [key, value] of Object.entries(searchParams!)) {
        url.searchParams.delete(key);
        if (value) {
            url.searchParams.append(key, value.toString());
        } else if (allowNull) {
            url.searchParams.append(key, "null");
        }
    }

    return url;
};

export const removeQueryParams = (
    pathname: string = window.location.pathname
) => {
    window.history.replaceState(null, "", pathname);
};

/**
 * Removes "https://", "http://" and "www." from the beginning of the URL.
 *
 * @param {string} url - the input URL
 * @return {string} the cleaned URL
 */
export const cleanUrl = (url: string): string => {
    return url.replace(/(https?:\/\/)?(www\.)?/, "");
};
