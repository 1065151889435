import { GenericModal } from "components/Modals/GenericModal";

export const ReportModal = () => {
    return (
        <GenericModal dismissButton="Close" title="Report a video">
            <p>
                Report inappropriate video content to{" "}
                <a href="mailto:reportcontent@switcherstudio.com">
                    reportcontent@switcherstudio.com
                </a>
                .
            </p>
        </GenericModal>
    );
};
