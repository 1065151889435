import { createSlice, PayloadAction, Reducer } from "@reduxjs/toolkit";

type ConsentTrackingState = {
    doNotTrack: boolean;
};

const initialState = {
    doNotTrack: false
} as ConsentTrackingState;

export const consentTracking = createSlice({
    name: "consentTracking",
    initialState: initialState,
    reducers: {
        setConsentTracking: (state, { payload }: PayloadAction<boolean>) => {
            state.doNotTrack = payload;
        }
    }
});

export const { setConsentTracking } = consentTracking.actions;

export default consentTracking.reducer as Reducer<ConsentTrackingState>;
