import { createAsyncThunk } from "@reduxjs/toolkit";
import { client as vpClient } from "api/playerClient";
import { Guid } from "helpers/guids";
import { OwnerResponse } from "@switcherstudio/player-api-client";

export const getCreator = createAsyncThunk<
    OwnerResponse,
    {
        catalogId: string | null | undefined;
        videoPlayerId: string;
        broadcastId: string | null | undefined;
    }
>(
    "playerCreator/getCreator",
    async ({ catalogId, videoPlayerId, broadcastId }) => {
        if (catalogId && catalogId !== Guid.empty) {
            return await vpClient.owner(catalogId);
        }
        if (videoPlayerId && videoPlayerId !== Guid.empty) {
            return await vpClient.owner2(videoPlayerId);
        }
        if (broadcastId && broadcastId !== Guid.empty) {
            return await vpClient.owner3(broadcastId);
        }
        return {} as OwnerResponse;
    }
);
