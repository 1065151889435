export interface DisplayAmountOptions extends Intl.NumberFormatOptions {
    locale?: string;
    signed?: boolean;
    compact?: boolean;
    ceil?: boolean;
}

export const displayAmount = (
    amount: number,
    {
        locale = "en-US",
        signed = false,
        compact = true,
        ...options
    }: DisplayAmountOptions = {}
) => {
    return Intl.NumberFormat(locale, {
        style: signed ? "currency" : "decimal",
        currency: "USD",
        notation: compact && amount % 100 === 0 ? "compact" : "standard",
        ...options
    }).format(amount / 100);
};
