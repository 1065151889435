import { useIsMountedRef } from "hooks/useIsMountedRef";
import React, { useEffect } from "react";
import { Notification, NotificationType } from "store/notification/types";
import styles from "./NotificationPopover.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

const DEFAULT_FADE = 2500;

interface NotificationPopoverProps {
    /** The notification. */
    notification: Notification;
    /** A handler function attached to the notification close button. */
    close: () => void;
    /** Whether this notification should fade or remain */
    shouldFade: boolean;
}

/** A notification popover. */
export const NotificationPopover: React.FC<NotificationPopoverProps> = ({
    notification,
    close,
    shouldFade = true
}: NotificationPopoverProps) => {
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        if (shouldFade) {
            // if a manual number is set, prefer that
            // Otherwise, errors do not auto-fade and
            // all other messages use DEFAULT_FADE
            let fade = notification.fadeMilliseconds
                ? notification.fadeMilliseconds
                : notification.type === NotificationType.Danger
                  ? -1
                  : DEFAULT_FADE;

            if (isMountedRef.current && fade > -1) {
                setTimeout(() => close(), fade);
            }
        }
    }, [isMountedRef, notification, close, shouldFade]);

    return (
        <>
            <div className="row justify-content-center">
                <div
                    className={cx(
                        "alert",
                        notification.type,
                        "alert-dismissible",
                        "show",
                        notification.class,
                        {
                            "fade": shouldFade
                        }
                    )}
                    role="alert"
                >
                    {notification.message}
                    {!!notification.clickText && !!notification.clickAction ? (
                        <div
                            onClick={() =>
                                !!notification.clickAction &&
                                notification.clickAction()
                            }
                            className={cx("click-action")}
                        >
                            {notification.clickText}
                        </div>
                    ) : null}
                    <button
                        type="button"
                        className={cx("close")}
                        data-dismiss="alert"
                        aria-label="Close"
                        onClick={close}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        </>
    );
};
