import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MobileDebugState } from "./types";

const initialState: MobileDebugState = {
    debugLog: []
};

export const mobileDebugLog = createSlice({
    name: "mobileDebugLog",
    initialState: initialState,
    reducers: {
        setDebugLog: (state, { payload }: PayloadAction<any>) => {
            const newLog = payload;
            return {
                debugLog: [newLog, ...state.debugLog].slice(0, 5)
            };
        }
    }
});

export const { setDebugLog } = mobileDebugLog.actions;

export default mobileDebugLog.reducer;
