import { useEffect } from "react";

export const useKeyPress = (targetKey: string, action: () => any) => {
    useEffect(() => {
        function downHandler({ key }: { key: string }) {
            if (key === targetKey) {
                action();
            }
        }

        document.addEventListener("keydown", downHandler);

        return () => {
            document.removeEventListener("keydown", downHandler);
        };
    }, [targetKey, action]);
};
