import { useMediaQuery } from "./useMediaQuery";

/** Returns if the viewport matches mobile breakpoints. Does not detect device type */
export const useIsMobile = () => {
    const isLandscapeMobile = useMediaQuery({
        maxWidth: 915,
        orientation: "landscape"
    });
    const isPortraitMobile = useMediaQuery({
        maxWidth: 720,
        orientation: "portrait"
    });

    return {
        isMobile: isLandscapeMobile || isPortraitMobile,
        isLandscapeMobile,
        isPortraitMobile
    };
};
