import { RefObject, useCallback, useEffect, useRef } from "react";

export interface UseClickOutsideOptions {
    /** If set to true, the initial click check is reset when the action is executed */
    resetOnClick?: boolean;
    ignoreInitialClick?: boolean;
}

/**
 * Custom hook to handle click events outside of a specified HTML element.
 *
 * This hook allows you to perform an action when a click event occurs outside
 * of the referenced element. It also supports resetting the click state based
 * on user-defined options.
 *
 * @template T - The type of the HTML element being referenced.
 * @param {RefObject<T> | RefObject<T>[]} ref - A reference to the HTML element to detect clicks outside of.
 * @param {() => void} action - A function to be executed when a click outside is detected.
 * @param {UseClickOutsideOptions} options - Configuration options for the hook.
 * @param {boolean} options.resetOnClick - Determines if the initial click state
 *                                           should be reset after the action is executed.
 * @returns {Object} - An object containing the `resetInitialClick` function,
 *                     which can be used to manually reset the initial click state.
 */
export const useClickOutside = <T extends HTMLElement>(
    ref: RefObject<T> | RefObject<HTMLElement>[],
    action: () => void,
    {
        resetOnClick = false,
        ignoreInitialClick = false
    }: UseClickOutsideOptions = {
        resetOnClick: false,
        ignoreInitialClick: true
    }
): object => {
    const initialClick = useRef(true);

    const resetInitialClick = useCallback(() => {
        initialClick.current = true;
    }, []);

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            const target = event.target as HTMLElement;

            if (ignoreInitialClick && initialClick.current) {
                initialClick.current = false;
                return;
            }

            if (Array.isArray(ref)) {
                if (
                    ref.every((r) => r.current && !r.current.contains(target))
                ) {
                    action();
                    if (resetOnClick) {
                        resetInitialClick();
                    }
                }
            } else {
                if (ref.current && !ref.current.contains(target)) {
                    action();
                    if (resetOnClick) {
                        resetInitialClick();
                    }
                }
            }
        }

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
            resetInitialClick();
        };
    }, [ref, action, resetInitialClick, resetOnClick, ignoreInitialClick]);

    return { resetInitialClick };
};
