import { pathToPage } from "helpers/analyticsHelpers";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "store/reducers";

export const useIdentifyTracking = () => {
    const location = useLocation();
    const isMicrosite = useSelector(
        (s: RootState) => s.videoSession.isMicrosite
    );
    const playerCreator = useSelector((s: RootState) => s.playerCreator);
    const {
        configuredCatalogId,
        configuredCollectionId,
        configuredBroadcastId
    } = useSelector((s: RootState) => s.catalogState);

    const [isIdentified, setIsIdentified] = useState<boolean>(false);

    const propertiesData = useMemo(
        () => ({
            playerId: configuredCollectionId,
            broadcastId: configuredBroadcastId,
            catalogId: configuredCatalogId,
            userId: playerCreator?.details?.userId,
            orgId: playerCreator?.details?.organizationId,
            stripeAccountId: playerCreator?.details?.stripeAccountId,
            viewSource: isMicrosite
                ? "Microsite"
                : pathToPage(location?.pathname)
        }),
        [
            location,
            playerCreator,
            isMicrosite,
            configuredCatalogId,
            configuredCollectionId,
            configuredBroadcastId
        ]
    );

    useEffect(() => {
        // Only track once
        if (isIdentified) return;

        // Only track if we have the necessary data
        const { userId, catalogId, playerId, broadcastId } = propertiesData;
        if (!userId || (!catalogId && !playerId && !broadcastId)) return;

        analytics.identify(userId, propertiesData);
        setIsIdentified(true);
    }, [propertiesData, isIdentified]);
};
